<template>
  <v-app>
    <div>
      <form class="form" @submit.stop.prevent="formOnsubmit()">
        <!--begin: Wizard Step 1-->
        <div class="pb-5" data-wizard-type="step-content" data-wizard-state="current">
          <h5 class="font-weight-bolder text-dark font-size-h6 font-size-h3-lg">
            <span>
              {{ title }}
            </span>
          </h5>

          <MainForm :form="form" :purpose="purpose">
            <template v-slot:main>
              <b-form-group id="input-group-academic_mentor">
                <label for="input-academic_mentor">Dosen Akademik:</label>
                <b-form-input id="input-academic_mentor" v-model="form.academic_mentor" placeholder="Dosen Akademik"></b-form-input>
                <small class="text-danger">{{ error.academic_mentor }}</small>
              </b-form-group>

              <b-form-group id="input-group-document_type">
                <label for="input-document_type">Jenis Dokumen:</label>
                <treeselect v-model="form.document_type" :multiple="false" placeholder="Pilih Dokumen" :options="document_type" />
                <small class="text-danger">{{ error.document_type }}</small>
              </b-form-group>

              <b-form-group id="input-group-attachment">
                <label for="input-attachment">Upload Lampiran DHS dan KHS: </label>
                <b-form-file v-model="form.attachment" :state="Boolean(form.attachment)" accept=".pdf" placeholder="Choose a file or drop it here..." drop-placeholder="Drop file here..." @change="chooseFile($event)"></b-form-file>
                <small class="text-danger">{{ error.attachment }}</small>
              </b-form-group>
              <b-form-group id="input-group-attachment2">
                <label for="input-attachment2">Upload Lampiran KRS: </label>
                <b-form-file v-model="form.attachment" :state="Boolean(form.attachment2)" accept=".pdf" placeholder="Choose a file or drop it here..." drop-placeholder="Drop file here..." @change="chooseFile($event)"></b-form-file>
                <small class="text-danger">{{ error.attachment2 }}</small>
              </b-form-group>
            </template>
          </MainForm>
        </div>
        <!--end: Wizard Step 1-->

        <!--begin: Wizard Actions -->
        <div class="d-flex justify-content-between border-top pt-10">
          <div>
            <b-button type="submit" variant="primary">Simpan</b-button>
            <b-button type="button" class="ml-2" variant="default" @click="$router.push('/letters/approval-request/list')"> Batal </b-button>
          </div>
        </div>
        <!--end: Wizard Actions -->
      </form>
    </div>
  </v-app>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import MainForm from "@/view/components/letters/Form.vue";

export default {
  props: {
    form: Object,
    route: String,
    title: String,
    purpose: String,
    currentPhoto: String,
  },
  components: {
    MainForm,
  },
  data() {
    return {
      document_type: [
        {
          id: "",
          label: "Pilih Jenis Dokumen",
          isDisabled: true,
        },
        {
          id: 1,
          value: 1,
          label: "DHS",
        },
        {
          id: 2,
          value: 2,
          label: "KRS",
        },
        {
          id: 3,
          value: 3,
          label: "KHS",
        },
      ],
      // form:{
      //     form_id: null
      // },
      formData: new FormData(),
      error: {
        semester_id: "",
        academic_mentor: "",
        document_type: "",
        attachment: "",
        attachment2: "",
        sign_image: "",
      },
      current_photo: "/img/default/blank.jpg",
      default_photo: "/img/default/blank.jpg",
      semesters: [],
    };
  },
  methods: {
    // attachment(data){
    //   console.log('data', data)
    //   this.form.form_id = data.form_id
    //   console.log('attachment', this.form)
    // //   this.$bvModal.show('modal-reply')
    // },
    chooseFile(e) {
      console.log("select file", e.target.files[0]);
      this.form.attachment = e.target.files[0];
    },
    chooseFile(e) {
      console.log("select file", e.target.files[0]);
      this.form.attachment2 = e.target.files[0];
    },
    async getSemesterActive() {
      let response = await module.get("api/semesters/now");
      // If Data Not Found
      if (response != null) {
        this.form.semester_id = response.id;
      }
    },
    async getSemesterOption() {
      let response = await module.setTreeSelect("api/semesters");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.semesters = response.data;
        this.semesters.unshift({
          label: "Pilih Semester",
          id: "",
          isDisabled: true,
        });
      }
    },
    onFileChange(e) {
      const file = e.target.files[0];

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        this.form.sign_image = e.target.files[0];

        reader.onload = (event) => {
          this.current_photo = event.target.result;
        };
        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    onRemove() {
      this.current_photo = null;
      this.form.photo = "";
    },
    validationCheck() {
      let status = true;
      // if (
      //   this.form.hasOwnProperty("interest") == false ||
      //   this.form.interest == ""
      // ) {
      //   this.error.interest = "Kolom kepeminatan tidak boleh kosong";
      //   status = false;
      // } else {
      //   this.error.interest = "";
      // }
      // if (
      //   this.form.hasOwnProperty("institution1") == false ||
      //   this.form.institution1 == ""
      // ) {
      //   this.error.institution1 = "Kolom instansi 1 tidak boleh kosong";
      //   status = false;
      // } else {
      //   this.error.institution1 = "";
      // }
      // if (
      //   this.form.hasOwnProperty("institution_address1") == false ||
      //   this.form.institution_address1 == ""
      // ) {
      //   this.error.institution_address1 =
      //     "Kolom alamat instansi 1 tidak boleh kosong";
      //   status = false;
      // } else {
      //   this.error.institution_address1 = "";
      // }
      // if (
      //   this.form.hasOwnProperty("institution2") == false ||
      //   this.form.institution2 == ""
      // ) {
      //   this.error.institution2 = "Kolom instansi 2 tidak boleh kosong";
      //   status = false;
      // } else {
      //   this.error.institution2 = "";
      // }
      // if (
      //   this.form.hasOwnProperty("institution_address2") == false ||
      //   this.form.institution_address2 == ""
      // ) {
      //   this.error.institution_address2 =
      //     "Kolom instansi 2 tidak boleh kosong";
      //   status = false;
      // } else {
      //   this.error.institution_address2 = "";
      // }
      // if (this.purpose == "add") {
      //   if (this.form.hasOwnProperty("sign_image") == false || this.form.sign_image == "") {
      //     this.error.sign_image = "Gambar TTD belum dipilih";
      //     status = false;

      //     this.$root.$emit("validationImageTrue");
      //     console.log("send emit");
      //   } else {
      //     this.error.sign_image = "";
      //     this.$root.$emit("validationImageFalse");
      //   }
      // }

      return status;
    },
    async formOnsubmit() {
      if (this.validationCheck() == false) {
        Swal.fire({
          title: "Gagal",
          text: "Harap periksa kembali form",
          icon: "error",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        throw new Error("Validation Error");
      }

      for (const [key, value] of Object.entries(this.form)) {
        this.formData.append(key, value);
      }

      // Make Request
      let response = await module.submit(this.formData, this.route);
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(this.error, response.error.errors);
      } else {
        // Success
        Swal.fire({
          title: response.success.title,
          text: response.success.message,
          icon: "success",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });

        this.$router.push("/letters/approval-request/list");
      }
    },
  },
  mounted() {
    this.getSemesterOption();
    this.getSemesterActive();
  },
  computed: {
    photo() {
      return this.current_photo == null ? this.default_photo : this.current_photo;
    },
  },
  watch: {
    form: function (newVal, oldVal) {
      if (this.purpose == "edit") {
        this.form = this.form;
        this.current_photo = this.form.sign;
        this.default_photo = this.form.sign;
      }
    },
  },
};
</script>

<style scoped>
.image-input-wrapper {
  width: 180px !important;
  height: 180px !important;
}

.image-input-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>
